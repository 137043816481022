// const BASE_URL = 'http://laiaosi.xingnuo.tech/xn_api.php'
// const BASE_URL = 'https://www.leols.cn//xn_api.php'
const isWWW = window.location.hostname.startsWith('www');
console.log(isWWW,'iw');
const BASE_URL = isWWW ? 'https://www.leols.cn/xn_api.php' : 'https://leols.cn/xn_api.php';

//生成请求方法
function request(params) { // 传参：params参数
    let {
        url, //	请求地址
        data = {}, //	参数信息
        method = 'POST', //	请求方式
        headers = {
            'content-type': 'application/x-www-form-urlencoded' // application/json
        }, //	请求头信息
    } = params
    // data.is_e = this.$i18n.locale == "en" ? 1 : 0 // 是否是英文1是，0否
    return new Promise((resolve, reject) => {
        this.$axios({
            method: method,
            headers: headers, // 请求头
            url: BASE_URL + url,
            data: this.$qs.stringify(data),
            xhrFields: {
                withCredentials: true
            }
        }).then((res) => {
            // 获取接口状态
            const soleData = res.data
            const {
                code
            } = soleData
            if (code == 1) {
                resolve(soleData.data)
            } else {
                console.log(res,'失败');
                reject(soleData)
            }
        }).catch(error => {
            console.log("err => ", error)
            reject(error)
        })
    })
}

module.exports = {
    BASE_URL,
    request
}